import { CabInput } from "@CabComponents/CabInput";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import colors from "../../../../colors";
import { ExecutiveContact } from "../../../../store";

export type EditContactModalProps = {
  control: Control<Pick<ExecutiveContact, "id" | "name" | "primary_email" | "is_resource">>;
  convertContactToResource: () => void
};

const EditContactModal = ({ control, convertContactToResource }: EditContactModalProps) => {
  return <Box display="flex" flexDirection="column" gap={2}>
    <Controller
      name="name"
      control={control}
      render={({ field }) => <FormControlLabel
        label="Name"
        labelPlacement="top"
        sx={{ textAlign: "left", width: "100%", margin: 0, alignItems: "start" }}
        control={<CabInput
          {...field}
          placeholder="Name"
          sx={{ width: "100%" }}
        />}
      />}
    />
    <Controller
      name="primary_email"
      control={control}
      render={({ field }) => <FormControlLabel
        label="Email"
        labelPlacement="top"
        sx={{ textAlign: "left", width: "100%", margin: 0, alignItems: "start" }}
        control={<CabInput
          {...field}
          disabled={true}
          placeholder="Name"
          sx={{ width: "100%" }}
        />}
      />}
    />
    <Typography
      onClick={() => convertContactToResource()}
      sx={{
        color: colors.blueLight,
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
          color: colors.black700,
        }
      }}
    >
      This contact is not a person (e.g. conference room)
    </Typography>
  </Box>;
};

export default EditContactModal;