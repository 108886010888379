import { useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { EventAnalysisInterval, EventAnalysisResponse } from "../../../store";
import theme from "../../../theme";
import BarChart from "../../Charts/BarChart/BarChart";

export type AnalyticsBarChartProps = {
  responseData?: EventAnalysisResponse;
  interval: EventAnalysisInterval;
  indexBy:  string;
  colorTransform: Record<string, string>
  keyBy: string
  loading: boolean
};

const  attemptToFormatDateTime = (value: string, indices: string[]): string => {
  
  if (isNaN(Date.parse(value))) {
    return value;
  }
  const dateTime = DateTime.fromISO(value);

  return indices.length < 10 ? dateTime.toFormat("EEEE MMMM d") : dateTime.toFormat("EEE MMM d");
};


const AnalyticsBarChart = ({
  responseData,
  interval,
  indexBy,
  colorTransform,
  keyBy
}: AnalyticsBarChartProps) => {

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const barGraphData = useMemo(() => {
    return responseData?.indices.map((period) => {
      const formattedKey = attemptToFormatDateTime(period, responseData.indices);
      const barDatum: {[key: string]: string | number} = {[indexBy]: formattedKey};
      if (responseData.data[period]) {
        responseData.data[period].data.forEach(val => {
          const indexValue = responseData.keys[val.id].name;
          barDatum[indexValue] = val.hours.toString();
        });
      }
      return barDatum;
    });
  }, [responseData, indexBy]);

  return <BarChart
    data={barGraphData || []}
    keys={Object.values(responseData?.keys || {})}
    intervalType={interval}
    colorTransform={colorTransform}
    metricType="hours"
    margin={{ top: 20, right: 40, bottom: isSmDown ? 150 : 110, left: 60 }}
    indexBy={indexBy}
    indices={responseData?.indices.map(index => attemptToFormatDateTime(index, responseData.indices)) || []}
    keyBy={keyBy}
  />;
};

export default AnalyticsBarChart;