import {  useMemo } from "react";
import { EventAnalysisKeyBy, EventAnalysisResponse } from "../../../store";
import PieChart from "../../Charts/PieChart/PieChart";
import { PieDatum } from "../../Charts/types";

export type AnalyticsPieChartProps = {
  colorTransform: Record<string, string>
  responseData?: EventAnalysisResponse
  keyBy: EventAnalysisKeyBy;
  chartHeight: number;
  loading: boolean
};

export const AnalyticsPieChart = ({
  responseData,
  colorTransform,
  keyBy,
  chartHeight,
  loading
}: AnalyticsPieChartProps) => {
  const pieData: PieDatum[] = useMemo(() => {
    return responseData ? Object.entries(responseData.meta).filter(
      ([key, value]) => key !== "total").map(([key, value]) => ({
      id: responseData.keys[key].name,
      label: responseData.keys[key].name,
      value: Number(value),
      color: responseData.keys[key].color
    })
    ) : [];
  }, [responseData]);
  
  return <PieChart
    data={pieData}
    colorTransform={colorTransform}
    metricType="hours"
    keyBy={keyBy}
    keys={Object.values(responseData?.keys || {})}
    chartHeight={chartHeight}
  />;
};

export default AnalyticsPieChart;