import React, { useCallback, useEffect, useState } from 'react';
import { ResponsiveBar, BarDatum, ComputedDatum, BarTooltipProps, BarSvgProps } from '@nivo/bar';
import { ChartTooltip } from '../ChartTooltip';
import { Box, useMediaQuery } from '@mui/material';
import Legend, { ActiveGraphKey, GraphKey } from '../Legend/Legend';
import theme from '../../../theme';
import colors from '../../../colors';

interface BarChartProps {
  data: BarDatum[];
  keys: GraphKey[];
  intervalType: string
  colorTransform: Record<string, string>
  metricType: string;
  indexBy: string
  indices: string[]
  margin?: BarSvgProps<BarDatum>["margin"]
  getKeyName?: (key: string) => string
  keyBy: string
}


const BarChart = ({
  data,
  indices,
  keys,
  intervalType,
  getKeyName,
  colorTransform,
  metricType,
  indexBy,
  keyBy,
  margin = { top: 20, right: 40, bottom: 85, left: 60 }
}: BarChartProps): React.ReactElement => {
  const [activeKeys, setActiveKeys] = useState<ActiveGraphKey[]>(
    keys.map(key => ({...key, active: true}))
  );

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setActiveKeys(keys.map(key => ({...key, active: true})));
    // Need to only re-render when keys actually change or when "Breakdown" is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys.map(key => key.name).join(";"), keyBy]);

  const getLabel = (value: number): string => {
    if (indices.length >= 20) {
      return (Math.round(value)).toString();
    } else {
      return (Math.round(value * 10) / 10).toString();
    }
  };

  const getColor = useCallback((datum: ComputedDatum<BarDatum>) => {
    return colorTransform[datum.id];
  }, [colorTransform]);

  const getTooltipLabel = (value: number) => (Math.round(value * 100) / 100).toString();

  const handleSetActiveKeys = (key: ActiveGraphKey) => {
    const newKeys = activeKeys.map(activeKey => {
      if (activeKey.id === key.id) {
        return {...activeKey, active: !activeKey.active};
      }
      return activeKey;
    });
    setActiveKeys(newKeys);
  };

  return (
    <Box 
      display="flex"
      flexDirection="column"
      height={`calc(100% - ${25 * Math.floor(keys.length / 5 + 1) }px)`}
      alignItems="center"
      flexWrap="nowrap"
      paddingBottom="36px"
    >
      <ResponsiveBar<BarDatum>
        data={data}
        keys={activeKeys.filter(key => key.active).map(key => key.name)}
        colors={(datum: ComputedDatum<BarDatum>) => getColor(datum)}
        indexBy={indexBy}
        groupMode={'stacked'}
        padding={0.3}
        innerPadding={2}
        animate={true}
        motionConfig={'stiff'}
        enableGridY={true}
        label={(datum: ComputedDatum<BarDatum>): string => getLabel(datum.value as number)}
        labelTextColor={colors.white900}
        labelSkipHeight={8}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: indices.length >= 10 || isSmDown ? 90 : 45,
          legendOffset: indices.length >= 7 ? 75 : 65,
          tickValues: indices,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: metricType,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        tooltip={(datum: BarTooltipProps<BarDatum>) =>
          <ChartTooltip label={datum.label} value={getTooltipLabel(datum.value)} color={datum.color} />
        }
        margin={margin}
      />
      <Legend keys={activeKeys} handleSetActiveKeys={handleSetActiveKeys} getKeyName={getKeyName}/>
    </Box>
  );
};

export default BarChart;
