import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ComputedDatum, PieTooltipProps, ResponsivePie } from '@nivo/pie';
import { PieDatum } from '../types';
import { ChartTooltip } from '../ChartTooltip';
import Legend, { ActiveGraphKey, GraphKey } from '../Legend/Legend';
import { EventAnalysisKeyBy } from '../../../store';
import { Box } from '@mui/material';
import colors from '../../../colors';


interface PieChartProps { 
  data: PieDatum[]; 
  colorTransform: Record<string, string>
  metricType: string;
  keys: GraphKey[]
  getKeyName?: ((name: string) => string) | undefined
  keyBy: EventAnalysisKeyBy;
  chartHeight: number;
}

const PieChart = ({
  data, colorTransform, keys, getKeyName, keyBy, chartHeight
}: PieChartProps): React.ReactElement => {
  const getColor = (datum: Omit<ComputedDatum<PieDatum>, 'fill' | 'color' | 'arc'>) => {
    return colorTransform[datum.id];
  };

  const [activeKeys, setActiveKeys] = useState<ActiveGraphKey[]>(
    keys.map(key => ({...key, active: true}))
  );

  useEffect(() => {
    setActiveKeys(keys.map(key => ({...key, active: true})));
    // Need to only re-render when keys actually change or when "Breakdown" is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys.map(key => key.name).join(";"), keyBy]);


  const handleSetActiveKeys = (key: ActiveGraphKey) => {
    const newKeys = activeKeys.map(activeKey => {
      if (activeKey.name === key.name) {
        return {...activeKey, active: !activeKey.active};
      }
      return activeKey;
    });
    setActiveKeys(newKeys);
  };

  const filteredData = useMemo(() => {
    const activeKeyIds = activeKeys.filter(key => key.active).map(key => key.name);
    return data.filter((datum) => activeKeyIds.includes(datum.id.toString()));
    // Need to only re-render when keys actually change, when "Breakdown" is changed, or when data is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeys.filter(key => key.active).map(key => key.id).join(";"), keyBy, data]);

  const total = useMemo(
    () => filteredData.length > 0 ? filteredData.map(datum => datum.value).reduce((a, b) => a + b) : 0, [filteredData]
  );

  const getLabel = useCallback((value: number, isArchLabel?: boolean): string => {
    const percent = total > 0 ? Math.round((value / total) * 100) : 0;
    if (isArchLabel) {
      return total > 0 ? `${percent.toString()}%` : '';
    } else {
      return total > 0 ? `${percent.toString()}% (${Math.round(value * 10) / 10} hours)` : '';
    }
  }, [total]);

  return <Box display="flex" flexDirection="column">
    <Box height={chartHeight}>
      <ResponsivePie<PieDatum>
        data={filteredData}
        padAngle={1}
        innerRadius={0.5}
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        animate={true}
        motionConfig={'stiff'}
        colors={(datum: Omit<ComputedDatum<PieDatum>, 'fill' | 'color' | 'arc'>) => getColor(datum)}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsStraightLength={7}
        arcLinkLabelsDiagonalLength={12}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={colors.white900}
        arcLabel={(datum: ComputedDatum<PieDatum>) => getLabel(datum.value, true)}
        //tooltipFormat={getLabel}
        tooltip={(datum: PieTooltipProps<PieDatum>) =>
          <ChartTooltip
            label={datum.datum.label.toString()}
            value={getLabel(datum.datum.value)} color={datum.datum.color}
          />
        }
        cornerRadius={5}
      />
    </Box>
    <Box >
      <Legend keys={activeKeys} handleSetActiveKeys={handleSetActiveKeys} getKeyName={getKeyName} />
    </Box>
  </Box>;
};

export default PieChart;