import { Box, Typography } from "@mui/material";
import colors from "../../colors";

interface ChartTooltipProps {
  label: string;
  value: string;
  color: string;
}

export const ChartTooltip = ({label, value, color}: ChartTooltipProps) => {
  return <Box 
    sx={{backgroundColor: colors.white900, borderRadius: "2px", padding: "4px 8px"}} 
    display="flex" 
    justifyContent="flex-start"
    boxShadow={2}
  >
    <Box 
      sx={{backgroundColor: color, width: "12px", height: "12px", margin: "auto 8px auto auto"}}/>
    <Typography variant="body1">{label}: <b>{value}</b></Typography>
  </Box>;
};