import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  AnalyticsETLLogAction,
  ContactAnalysisResponse, EventAnalysisIndexBy, EventAnalysisInterval,
  EventAnalysisKeyBy, EventAnalysisResponse, ExecutiveContact, Leader, RootState
} from "..";
import { getLeadersForScheduling } from "../../utils/leaderUtils";
import { fetchCalendars } from "../schedule/actions";
import { FetchReturn, ThunkDispatchType } from "../types";
import { 
  clearAnalyticsEvents,
  clearExecutiveContacts,
  fetchAnalyticsEventCategories, fetchAnalyticsEventTypes,
  fetchAnalyticsExecutiveContactCategories, fetchAnalyticsExecutiveContactTypes, fetchConsolidateLocations,
  fetchContactAnalysis, fetchEventAnalysis, fetchRecurrenceTypes, updateAnalyticsExecutiveContact
} from "./actions";
import { PermissionError } from "../../utils/permissionUtils";
import { useMountEffect } from "../../utils/hooks";


export const useAnalyticsInsightsState = () => {
  const user = useSelector((state: RootState) => (state.auth.user));

  const leaders = useSelector((state: RootState) => (state.leaders));

  const {
    event_categories,
    event_types,
    consolidated_locations,
    executive_contacts_analysis: executive_contacts,
    executive_contact_categories,
    recurrence_types,
    contact_types
  } = useSelector((state: RootState) => (state.eventAnalytics));

  const calendars = useSelector((state: RootState) => (state.schedule.calendars));

  const dispatch = useDispatch<ThunkDispatchType>();

  //query data
  const [start, setStart] = useState<DateTime | undefined>();
  const [end, setEnd] = useState<DateTime | undefined>();
  // analytics data
  const [indexBy, setIndexBy] = useState<EventAnalysisIndexBy>(EventAnalysisIndexBy.PERIOD);
  const [keyBy, setKeyBy] = useState<EventAnalysisKeyBy>(EventAnalysisKeyBy.CATEGORY_KEY);
  const [interval, setInterval] = useState<EventAnalysisInterval>(EventAnalysisInterval.DAY_INTERVAL);


  const myLeaders = useMemo(() => getLeadersForScheduling(leaders.leaders), [leaders.leaders]);
  const [selectedLeader, setSelectedLeader] = useState<Leader | null>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRows, ] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [etlStatus, setEtlStatus] = useState<AnalyticsETLLogAction | undefined>();
  const [maxDate, setMaxDate] = useState<DateTime | null | undefined>();
  const [minDate, setMinDate] = useState<DateTime | null | undefined>();
  const [contactGridFilterParameters, setContactGridFilterParameters] = useState<
    Record<string, string | number | (string | number)[]>
  >({});

  const [
    analyticsEventResponseData,
    setAnalyticsEventResponseData
  ] = useState<EventAnalysisResponse | undefined>();
  const [
    analyticsContactResponseData,
    setAnalyticsContactResponseData
  ] = useState<ContactAnalysisResponse["data"] | undefined>();
  
  useEffect(() => {
    if (selectedLeader === null) {
      setSelectedLeader(myLeaders[0] || null);
    }
    // ignore selectedLeader because it will trigger this effect every time it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLeaders]);

  useEffect(() => {
    if (calendars.length === 0) {
      dispatch(fetchCalendars());
    }
  }, [calendars, dispatch]);

  useMountEffect(() => {
    setInitialLoading(true);
    dispatch(clearExecutiveContacts());
    dispatch(clearAnalyticsEvents());
    const initialFetch = async () => {
      const locationsRes = dispatch(fetchConsolidateLocations());
      const recurrenceRes = dispatch(fetchRecurrenceTypes());
      const categoryRes = dispatch(fetchAnalyticsEventCategories());
      const typesRes = dispatch(fetchAnalyticsEventTypes());
      const execTypesRes = dispatch(fetchAnalyticsExecutiveContactCategories());
      const execContactCateRes = dispatch(fetchAnalyticsExecutiveContactTypes());
      await Promise.all([locationsRes, categoryRes, typesRes, execContactCateRes, recurrenceRes, execTypesRes]);
      setInitialLoading(false);
    };
    initialFetch();
  });

  const fetchAnalysis = useCallback(async ( 
    curSelectedLeader: Leader, curStart: DateTime, curEnd: DateTime,
    curContactGridFilterParameters: Record<string, string | number | (string | number)[]>,
    curIndexBy: EventAnalysisIndexBy, curInterval: EventAnalysisInterval, curKeyBy: EventAnalysisKeyBy
  ) => {
    setLoading(true);
    const event_ana_res: Promise<FetchReturn<EventAnalysisResponse, PermissionError>> = dispatch(fetchEventAnalysis({
      index_by: curIndexBy,
      key_by: curKeyBy,
      interval: curInterval,
      query: {
        start_date: curStart.toISO() || "",
        end_date: curEnd.toISO() || "",
        leaders: [curSelectedLeader?.id.toString()]
      }
    }));

    const event_con_res = dispatch(fetchContactAnalysis({
      start_date: curStart.toISO() || "",
      end_date: curEnd.toISO() || "",
      leaders: [curSelectedLeader.id.toString()],
      ...curContactGridFilterParameters
    }));

    const [event_ana_res_data, event_con_res_data] = await Promise.all([event_ana_res, event_con_res]);

    if (event_con_res_data?.status === 200) {
      setAnalyticsContactResponseData(event_con_res_data.data.data);
    }

    if (event_ana_res_data.status === 200) {

      setEtlStatus(event_ana_res_data.data.aggregates.most_recent_log || AnalyticsETLLogAction.NOT_FETCHED);
      setMaxDate(
        event_ana_res_data.data.aggregates.max_end_date ? DateTime.fromISO(
          event_ana_res_data.data.aggregates.max_end_date
        ) : null
      );
      setMinDate(
        event_ana_res_data.data.aggregates.min_start_date ? DateTime.fromISO(
          event_ana_res_data.data.aggregates.min_start_date
        ) : null
      );
      setAnalyticsEventResponseData(event_ana_res_data.data);
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (!!consolidated_locations && selectedLeader && start && end && !initialLoading) {
      fetchAnalysis(selectedLeader, start, end, contactGridFilterParameters, indexBy, interval, keyBy);
    }
  }, [
    consolidated_locations, end, fetchAnalysis, selectedLeader,
    start, indexBy, interval, keyBy, initialLoading, contactGridFilterParameters
  ]);

  const updateExecutiveContact = useCallback(async (data: Partial<ExecutiveContact> & {id: number}) => {
    setLoading(true);
    await dispatch(updateAnalyticsExecutiveContact(data));
    setLoading(false);
  }, [dispatch]);

  const handleChangeDates = (startDate: DateTime | undefined, endDate: DateTime | undefined) => {
    setStart(startDate);
    setEnd(endDate);
    setLoading(true);
  };

  return {
    start,
    end,
    event_categories,
    event_types,
    consolidated_locations,
    recurrence_types,
    executive_contacts,
    user,
    myLeaders,
    pageNumber,
    totalRows,
    initialLoading,
    loading: loading || initialLoading,
    setLoading: setLoading,
    handleChangeDates: handleChangeDates,
    handleSetLeader: setSelectedLeader,
    selectedLeader,
    handleSetLoading: setInitialLoading,
    handleSetPageNumber: setPageNumber,
    executive_contact_categories,
    analyticsEventResponseData,
    analyticsContactResponseData,
    indexBy,
    keyBy,
    interval,
    handleSetInterval: setInterval,
    handleSetIndexBy: setIndexBy,
    handleSetKeyBy: setKeyBy,
    updateExecutiveContact,
    etlStatus,
    maxDate,
    minDate,
    updateContactDataGrid: setContactGridFilterParameters,
    contactGridFilterParameters,
    contact_types
  };
};
